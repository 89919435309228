<template>
  <section id="myInformation" class="flex flex-col gap-y-12 h-full">
    <div>
      <div class="flex lg:hidden flex-row bg-white py-3">
        <svg
          class="cursor-pointer"
          @click="openMobileNav"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="#111"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 18l-6-6 6-6"
          />
        </svg>
        <div class="flex-1 flex justify-center font-EffraM text-center text-lg">
          Mes rendez-vous
        </div>
      </div>
      <!-- Active Code : #FD5B68 | #000 -->
      <nav class="bg-white lg:bg-transparent flex flex-row px-0 lg:px-8">
        <a
          href="#"
          class="flex items-center gap-x-2 text-lg py-3 px-2"
          :class="
            page === 1
              ? 'text-rosDok-ultra border-0 border-b-2 border-solid border-rosDok-ultra font-EffraM'
              : 'text-grayDok-sidebar border-0 border-b-2 border-solid border-grayborder font-EffraR'
          "
          @click="page = 1"
        >
          Prochains
        </a>
        <a
          href="#"
          class="flex items-center gap-x-2 font-EffraR text-lg py-3 px-2"
          :class="
            page === 2
              ? 'text-rosDok-ultra border-0 border-b-2 border-solid border-rosDok-ultra font-EffraM'
              : 'text-grayDok-sidebar border-0 border-b-2 border-solid border-grayborder font-EffraR'
          "
          @click="page = 2"
        >
          Historique
        </a>
      </nav>
    </div>

    <div class="flex-1">
      <div class="grid gap-x-8 gap-y-8 grid-cols-1 w-full px-8">
        <!---------------------Upcomming------------------------->
        <template v-if="page === 1">
          <div
            v-for="(item, index) in data"
            :key="index"
            id="card"
            class="border border-solid border-grayborder rounded-cu"
          >
            <div
              id="card-header"
              :class="
                `flex justify-between items-center ${colorByStatus(
                  item.status
                )} font-EffraM text-base py-5 px-3`
              "
            >
              <div class="flex flex-row items-center gap-x-3">
                <span
                  v-html="iconByStatus(item.status)"
                  class="p-0 m-0 flex items-center justify-center"
                ></span>
                {{ messageByStatus(item.status) }}
              </div>
              <div id="actions" class="flex items-center gap-x-6">
                <svg
                  @click="onClickEvent(item)"
                  class="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                  />
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 15c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"
                  />
                </svg>
                <svg
                  class="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18 6L6 18M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div id="card-content" class="py-5 px-3 bg-white">
              <div class="grid gap-x-20 gap-y-4 grid-cols-2 lg:grid-cols-4">
                <div class="flex flex-row gap-x-2 items-start">
                  <div id="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 16 16"
                    >
                      <path
                        stroke="#111"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12.667 2.667H3.333C2.597 2.667 2 3.264 2 4v9.333c0 .737.597 1.334 1.333 1.334h9.334c.736 0 1.333-.597 1.333-1.334V4c0-.736-.597-1.333-1.333-1.333zM10.666 1.333V4M5.333 1.333V4M2 6.667h12"
                      />
                    </svg>
                  </div>
                  <div class="flex flex-col">
                    <div
                      id="title"
                      class="text-base font-EffraR text-labelGray"
                    >
                      Date
                    </div>
                    <div class="text-lg text-black font-EffraR">
                      {{ item.startAt | moment("DD MMM YY") }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-row gap-x-2 items-start">
                  <div id="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 16 16"
                    >
                      <path
                        stroke="#111"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 14.667c3.682 0 6.667-2.985 6.667-6.667S11.682 1.333 8 1.333 1.333 4.318 1.333 8 4.319 14.667 8 14.667z"
                      />
                      <path
                        stroke="#111"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 4v4l2.667 1.333"
                      />
                    </svg>
                  </div>
                  <div class="flex flex-col">
                    <div
                      id="title"
                      class="text-base font-EffraR text-labelGray"
                    >
                      Heure
                    </div>
                    <div class="text-lg text-black font-EffraR">
                      {{ item.startAt | moment("HH:mm") }}
                    </div>
                  </div>
                </div>
                <div class="hidden lg:flex flex-row gap-x-2 items-start">
                  <div id="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 16 16"
                    >
                      <path
                        stroke="#111"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13.333 14v-1.333c0-.708-.28-1.386-.78-1.886-.501-.5-1.18-.781-1.887-.781H5.333c-.707 0-1.385.281-1.885.781s-.781 1.178-.781 1.886V14M8 7.333c1.473 0 2.667-1.194 2.667-2.666C10.667 3.194 9.473 2 8 2 6.527 2 5.333 3.194 5.333 4.667c0 1.472 1.194 2.666 2.667 2.666z"
                      />
                    </svg>
                  </div>
                  <div class="flex flex-col">
                    <div
                      id="title"
                      class="text-base font-EffraR text-labelGray"
                    >
                      Praticien
                    </div>
                    <div class="text-lg text-black font-EffraR">
                      <router-link
                        :to="{
                          name: 'profileDoctor',
                          params: {
                            slug: item.owner.slug
                              ? item.owner.slug
                              : item.owner._id
                          }
                        }"
                        target="_blank"
                        class="text-black"
                      >
                        {{
                          `${item.owner.title}. ${item.owner.firstName} ${item.owner.lastName}`
                        }}
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="hidden lg:flex flex-row gap-x-2 items-start">
                  <div id="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 16 16"
                    >
                      <g clip-path="url(#clip0)">
                        <path
                          stroke="#111"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4.667 4.667h.006m9.054 4.273l-4.78 4.78c-.124.124-.271.222-.433.29-.162.066-.335.101-.51.101-.176 0-.35-.035-.511-.102-.162-.067-.309-.165-.433-.289L1.334 8V1.333H8l5.727 5.727c.248.25.388.588.388.94s-.14.69-.388.94z"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <path fill="#fff" d="M0 0H16V16H0z" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div class="flex flex-col">
                    <div
                      id="title"
                      class="text-base font-EffraR text-labelGray"
                    >
                      Motif
                    </div>
                    <div class="text-lg text-black font-EffraR">
                      {{ item.category.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!---------------------History------------------------->
        <template v-if="page === 2">
          <div
            class="bg-white border border-solid border-grayborder rounded-cu flex flex-col lg:flex-row"
            v-for="(item, index) in data"
            :key="index"
          >
            <div
              class="flex-1 border-0 border-r-0 lg:border-r border-solid border-grayborder p-5"
            >
              <div
                class="grid gap-y-3 lg:gap-y-0 gap-x-2 lg:gap-x-20 grid-cols-2 lg:grid-cols-4"
              >
                <div class="flex flex-row gap-x-2 items-start">
                  <div id="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 16 16"
                    >
                      <path
                        stroke="#111"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12.667 2.667H3.333C2.597 2.667 2 3.264 2 4v9.333c0 .737.597 1.334 1.333 1.334h9.334c.736 0 1.333-.597 1.333-1.334V4c0-.736-.597-1.333-1.333-1.333zM10.666 1.333V4M5.333 1.333V4M2 6.667h12"
                      />
                    </svg>
                  </div>
                  <div class="flex flex-col">
                    <div
                      id="title"
                      class="text-base font-EffraR text-labelGray"
                    >
                      Date
                    </div>
                    <div class="text-lg text-black font-EffraR">
                      {{ item.startAt | moment("DD MMM YY") }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-row gap-x-2 items-start">
                  <div id="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 16 16"
                    >
                      <path
                        stroke="#111"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12.667 2.667H3.333C2.597 2.667 2 3.264 2 4v9.333c0 .737.597 1.334 1.333 1.334h9.334c.736 0 1.333-.597 1.333-1.334V4c0-.736-.597-1.333-1.333-1.333zM10.666 1.333V4M5.333 1.333V4M2 6.667h12"
                      />
                    </svg>
                  </div>
                  <div class="flex flex-col">
                    <div
                      id="title"
                      class="text-base font-EffraR text-labelGray"
                    >
                      Heure
                    </div>
                    <div class="text-lg text-black font-EffraR">
                      {{ item.startAt | moment("HH:mm") }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-row gap-x-2 items-start">
                  <div id="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 16 16"
                    >
                      <path
                        stroke="#111"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13.333 14v-1.333c0-.708-.28-1.386-.78-1.886-.501-.5-1.18-.781-1.887-.781H5.333c-.707 0-1.385.281-1.885.781s-.781 1.178-.781 1.886V14M8 7.333c1.473 0 2.667-1.194 2.667-2.666C10.667 3.194 9.473 2 8 2 6.527 2 5.333 3.194 5.333 4.667c0 1.472 1.194 2.666 2.667 2.666z"
                      />
                    </svg>
                  </div>
                  <div class="flex flex-col">
                    <div
                      id="title"
                      class="text-base font-EffraR text-labelGray"
                    >
                      Pratcien
                    </div>
                    <div class="text-lg text-black font-EffraR">
                      <router-link
                        :to="{
                          name: 'profileDoctor',
                          params: {
                            slug: item.owner.slug
                              ? item.owner.slug
                              : item.owner._id
                          }
                        }"
                        target="_blank"
                        class="text-black"
                      >
                        {{
                          `${item.owner.title}. ${item.owner.firstName} ${item.owner.lastName}`
                        }}
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row gap-x-2 items-start">
                  <div id="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 16 16"
                    >
                      <g clip-path="url(#clip0)">
                        <path
                          stroke="#111"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4.667 4.667h.006m9.054 4.273l-4.78 4.78c-.124.124-.271.222-.433.29-.162.066-.335.101-.51.101-.176 0-.35-.035-.511-.102-.162-.067-.309-.165-.433-.289L1.334 8V1.333H8l5.727 5.727c.248.25.388.588.388.94s-.14.69-.388.94z"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <path fill="#fff" d="M0 0H16V16H0z" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div class="flex flex-col">
                    <div
                      id="title"
                      class="text-base font-EffraR text-labelGray"
                    >
                      Motif
                    </div>
                    <div class="text-lg text-black font-EffraR">
                      {{ item.category.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex flex-row gap-x-5 items-center justify-center px-10 py-8 "
            >
              <dok-button
                :has-icon="false"
                bg="action"
                custom-class="flex items-center gap-x-2 button-tooltip hover:bg-gray-100"
                data-tooltip="Voir le rendez-vous"
                @click.native="onClickEvent(item)"
              >
                <svg
                  class="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                  />
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 15c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"
                  />
                </svg>
                <span class="block lg:hidden">Plus d'infos</span>
              </dok-button>
              <dok-button
                :has-icon="false"
                bg="action"
                custom-class="flex items-center gap-x-2 button-tooltip hover:bg-gray-100"
                data-tooltip="Reprendre rendez-vous"
              >
                <svg
                  class="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 4v6h6M23 20v-6h-6"
                  />
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M20.49 9c-.507-1.433-1.37-2.715-2.505-3.725-1.137-1.01-2.51-1.715-3.993-2.05-1.483-.336-3.027-.29-4.487.132S6.715 4.565 5.64 5.64L1 10m22 4l-4.64 4.36c-1.075 1.075-2.404 1.86-3.865 2.283-1.46.423-3.004.468-4.487.133-1.483-.336-2.856-1.041-3.993-2.051C4.88 17.715 4.017 16.433 3.51 15"
                  />
                </svg>
                <span class="block lg:hidden">Reprendre</span>
              </dok-button>
            </div>
          </div>
        </template>
      </div>
    </div>

    <modal
      :is-active="modal.is"
      :exit-modal="() => {}"
      :hidden-header="true"
      :no-padding="true"
      :width="600"
      :has-no-scroll="true"
    >
      <div
        class="font-EffraR py-5 px-3 flex justify-between rounded-t-lg"
        :class="colorByStatus(modal.data.status)"
      >
        <!-- ? 'bg-dokYellow-bg text-dokYellow-text' ? '' -->

        <div id="title" class="flex flex-row gap-x-2 items-center font-EffraM">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
            v-if="modal.data.status === 'ACCEPTED'"
          >
            <path
              stroke="#30971F"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 4.5l-8.25 8.25L3 9"
            />
          </svg>

          <svg
            v-if="modal.data.status === 'PENDING'"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="#AE851A"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 1.5v3M9 13.5v3M3.697 3.698L5.82 5.82M12.18 12.18l2.123 2.123M1.5 9h3M13.5 9h3M3.697 14.303L5.82 12.18M12.18 5.82l2.123-2.122"
            />
          </svg>

          Détail du rendez-vous
        </div>
        <svg
          @click="modalClose"
          class="cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M18 6L6 18M6 6l12 12"
          />
        </svg>
      </div>
      <div id="content" class="p-5">
        <appointment-info :event="modal.data"></appointment-info>
      </div>
    </modal>
  </section>
</template>

<style scoped>
.button-tooltip {
  position: relative;
}

.button-tooltip::after {
  content: attr(data-tooltip);
  background: #383838;
  border-radius: 4px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  color: white;
  height: 34px;
  bottom: -45px;
  left: -50px;
}

.button-tooltip::after,
.button-tooltip::before {
  position: absolute;
  display: none;
}

.button-tooltip:hover::after,
.button-tooltip:hover::before {
  display: block;
  @apply flex items-center justify-center font-EffraR px-5;
}
</style>

<script>
const modal = () => import("@/views/global-components/modal.vue");
const appointmentInfo = () => import("./layouts/appointmentInfo.vue");
import { EventBus } from "@/event-bus";

export default {
  components: { modal, appointmentInfo },
  data() {
    return {
      page: 1,
      modal: { is: false, data: {} },
      data: []
    };
  },
  created() {
    this.getList();
  },
  computed: {
    upCommping() {
      return this.data.filter(item => {
        return item.status === "PENDING";
      });
    },
    historyData() {
      return this.data.filter(item => {
        return item.status === "ACCEPTED";
      });
    }
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getList();
      }
    }
  },
  methods: {
    iconByStatus(status) {
      switch (status) {
        case "DONE":
          return `
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 6L9 17l-5-5"/>
          </svg>
          `;
          break;
        case "PENDING":
          return `
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"/>
          </svg>
          `;
          break;
        case "CANCELED":
          return `
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM15 9l-6 6M9 9l6 6"/>
          </svg>
          `;
          break;
        case "REJECTED":
          return `
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM4.93 4.93l14.14 14.14"/>
          </svg>
          `;
          break;
        case "ACCEPTED":
          return `
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 6L9 17l-5-5"/>
          </svg>
          `;
          break;
        case "TRANSFERED":
          return `
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 3h5v5M4 20L21 3M21 16v5h-5M15 15l6 6M4 4l5 5"/>
          </svg>
          `;
          break;
        case "OWNER-TRANSFERED":
          return ``;
          break;
        case "UNPLANNED":
          return ``;
          break;
      }
    },
    colorByStatus(status) {
      switch (status) {
        case "DONE":
          return "bg-dokBlue-lighter text-dokBlue-blue";
          break;
        case "PENDING":
          return "bg-dokYellow-bg text-dokYellow-text";
          break;
        case "CANCELED":
          return "bg-red-100 text-red-600";
          break;
        case "REJECTED":
          return "bg-red-100 text-red-600";
          break;
        case "ACCEPTED":
          return "bg-green-100 text-green-600";
          break;
        case "TRANSFERED":
          return "bg-dokBlue-lighter text-dokBlue-blue";
          break;
        case "OWNER-TRANSFERED":
          return "bg-gray-100 text-gray-600";
          break;
        case "UNPLANNED":
          return "bg-gray-100 text-gray-600";
          break;
      }
    },
    messageByStatus(status) {
      switch (status) {
        case "DONE":
          return "Terminé";
          break;
        case "PENDING":
          return "En attente";
          break;
        case "CANCELED":
          return "Annulé";
          break;
        case "REJECTED":
          return "Refusé";
          break;
        case "ACCEPTED":
          return "Accepté";
          break;
        case "TRANSFERED":
          return "Reporté";
          break;
        // case "OWNER-TRANSFERED":
        //   return "OWNER-TRANSFERED";
        //   break;
        // case "UNPLANNED":
        //   return "UNPLANNED";
        //   break;
      }
    },
    modalClose() {
      this.modal.is = false;
      this.modal.data = {};
    },
    openMobileNav() {
      EventBus.$emit("Change_Page_Number_My_Account_Patient", -1);
    },
    onClickEvent(item) {
      this.modal.data = item;

      this.$nextTick(() => {
        this.modal.is = true;
      });
    },
    async getList() {
      this.$vs.loading();
      await this.$store.dispatch(`user/GET_APPOINTMENTS`, {
        params: { status: this.page === 1 ? "upcoming" : "passed" },
        onData: ({ ok, data }) => {
          if (ok) {
            this.data = data;

            this.$nextTick(() => {
              this.$vs.loading.close();
            });
            return;
          }

          this.$vs.loading.close();
        }
      });
    }
  }
};
</script>
